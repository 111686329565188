<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section">
  <b-modal v-model="showLoginModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Détail de poste
    </template>
    <b-popover
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="auto"
      container="my-container"
      ref="popover"
    >
      <template #title>
        CONFIRMATION
      </template>
      <b-button @click="onClose" size="sm" style="margin-right:10px" variant="danger">Cancel</b-button>
      <b-button @click="delete_post" size="sm" variant="primary">Ok</b-button>
    </b-popover>
    <v-container class="py-0">
        <v-form @submit.prevent="changeFees">
        <v-row>
        <v-col cols="12" md="12">
          <v-text-field v-model="packageDetail.home_fees" type="number" label="Frais de livraison domicile" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <b-form-select style="margin-top:15px" v-model="packageDetail.dlv_type">
            <option value="both">post par défault</option>
            <option value="homedlv">post à domicile</option>
            <option value="stopdesk">post stop desk</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6" style="text-align:right">
          <v-btn small depressed style="background-color:#4caf50" @click="addFeesException" class="mr-0">Ajouter une exception de frais de transport</v-btn>
        </v-col>
        <v-row v-for="(feesException, index) in feesExceptions" :key="feesException.post_code" :value="feesException.post_code">
          <v-col cols="12" md="6">
          <label>Poste</label>
          <b-form-select style="margin-top:15px" v-model="feesException.post_code">
            <option v-for="post in rows" :key="post.code" :value="post.code">{{post.name}}->{{post.wilaya}}</option>
          </b-form-select>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="feesException.fees" type="number" label="Frais de livraison entre postes" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="1">
            <b-button-close style="margin-top:20px" @click="removeFeesExeception(index)"/>
          </v-col>
        </v-row>
        <v-col cols="12" md="6" >
          <v-btn id="popover-reactive-1" style="background-color:#D50000" class="mr-0">
            SUPPRIMER
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Enregistrer
          </v-btn>

        </v-col>
        </v-row>
        </v-form>
    </v-container>
  </b-modal>
  <b-modal v-model="showWilayaModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Ajouter une destination
    </template>
    <div style="width:100%;text-align:center"><span style="color:red">{{newPostErrorMessage}}</span></div>
    <v-container class="py-0">
        <b-form @submit.prevent="addNewWilaya">
          <v-row>
          <v-col cols="12" md="6">
            <!-- <v-text-field v-model="selectedDestination" required placeholder="Destination" label="Destination" class="purple-input"/> -->
            <multiselect required v-model="selectedDestination" tag-placeholder="Destination" placeholder="Destination" label="name" track-by="name" :options="wilayas" :multiple="false" :taggable="true" @tag="addTag" @input="updateSelectedWilaya1"></multiselect>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="selectedDestinationFees" required placeholder="Frais de déchargement" type="number" label="Frais de déchargement" class="purple-input"/>
          </v-col>
          </v-row>
          <v-col cols="12" class="text-right">
            <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
              Ajouter
            </v-btn>
          </v-col>
        </b-form>
    </v-container>
  </b-modal>
  <b-modal v-model="showPostModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Ajouter un prix
    </template>
    <div style="width:100%;text-align:center"><span style="color:red">{{newPostErrorMessage}}</span></div>
    <v-container class="py-0">
        <b-form @submit.prevent="addNewPrice">
        <v-row>
        <v-col cols="12" md="6">
          <label>Wilaya From</label>
          <!-- <multiselect required v-model="selectedWilaya1" tag-placeholder="Lieu de départ" placeholder="Lieu de départ" label="name" track-by="name" :options="wilayas" :multiple="false" :taggable="true" @input="updateSelectedWilaya1"></multiselect> -->
          <b-form-select required v-model="selectedInfo.selectedWilaya1">
            <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <label>Wilaya To</label>
          <!-- <multiselect required v-model="selectedWilaya2" tag-placeholder="Lieu de départ" placeholder="Lieu de départ" label="name" track-by="name" :options="wilayas" :multiple="false" :taggable="true" @input="updateSelectedWilaya2"></multiselect> -->
          <b-form-select required v-model="selectedInfo.selectedWilaya2">
            <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <label>Commune From</label>
          <!-- <multiselect required v-model="selectedWilaya1" tag-placeholder="Lieu de départ" placeholder="Lieu de départ" label="name" track-by="name" :options="wilayas" :multiple="false" :taggable="true" @input="updateSelectedWilaya1"></multiselect> -->
          <b-form-select v-model="selectedInfo.selectedCommunue1">
            <option :key="''" :value="''"></option>
            <option v-for="communue in selectedInfo.selectedWilaya1.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <label>Communue To</label>
          <!-- <multiselect required v-model="selectedWilaya2" tag-placeholder="Lieu de départ" placeholder="Lieu de départ" label="name" track-by="name" :options="wilayas" :multiple="false" :taggable="true" @input="updateSelectedWilaya2"></multiselect> -->
          <b-form-select v-model="selectedInfo.selectedCommunue2">
            <option :key="''" :value="''"></option>
            <option v-for="communue in selectedInfo.selectedWilaya2.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field disabled placeholder="Fourgon 400 KG" label="Fourgon 400 KG" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="selectedInfo.vehicule[0]" placeholder="0" type="number" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field disabled placeholder="Fourgon 1.5 TN" label="Fourgon 1.5 TN" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="selectedInfo.vehicule[1]" placeholder="0" type="number" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field disabled placeholder="Camion 2.5 TN" label="Camion 2.5 TN" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="selectedInfo.vehicule[2]" placeholder="0" type="number" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field disabled placeholder="Camion 3.5 TN" label="Camion 3.5 TN" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="selectedInfo.vehicule[3]" placeholder="0" type="number" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field disabled placeholder="Camion 10 TN" label="Camion 10 TN" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="selectedInfo.vehicule[4]" placeholder="0" type="number" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field disabled placeholder="Camion 20 TN" label="Camion 20 TN" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="selectedInfo.vehicule[5]" placeholder="0" type="number" class="purple-input"/>
        </v-col>
        </v-row>
        <v-col cols="12" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Ajouter
          </v-btn>
        </v-col>
        </b-form>
    </v-container>
  </b-modal>
  <base-material-card icon="mdi-package-variant-closed" title="Liste des vendeurs" class="px-5 py-3">
  <template v-slot:heading>
    <v-icon @click="reloadItems">mdi-reload</v-icon>
  </template>
   <div style="width:100%;text-align:right">
     <!-- <v-btn color="blue" small depressed @click="showWilayaModal = true">Ajouter une destination</v-btn> -->
     <v-btn color="success" small depressed @click="showPostModal = true">Ajouter un prix</v-btn>
   </div>
    <vue-good-table
      max-height="700px"
      :columns="columns"
      :isLoading.sync="isLoading"
      :rows="rows"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
      style-class="vgt-table condensed bordered tableOne"
    >
    <template slot="loadingContent">
        <div class="spinner spinner-primary mx-auto my-auto"></div>
    </template>
    <!-- <template slot="column-filter" slot-scope="props">
      <b-form-datepicker v-if="props.column.filterOptions.customFilterDate" @input="handleCustomFilterDate"></b-form-datepicker>
    </template> -->
    <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'fees__0'">
          {{props.row.prices[0]}}
        </span>
        <span v-if="props.column.field == 'fees__1'">
          {{props.row.prices[1]}}
        </span>
        <span v-if="props.column.field == 'fees__2'">
          {{props.row.prices[2]}}
        </span>
        <span v-if="props.column.field == 'fees__3'">
          {{props.row.prices[3]}}
        </span>
        <span v-if="props.column.field == 'fees__4'">
          {{props.row.prices[4]}}
        </span>
        <span v-if="props.column.field == 'fees__5'">
          {{props.row.prices[5]}}
        </span>
        <span v-else>
          {{props.row[props.column.field]}}
        </span>
    </template>
    </vue-good-table>
  </base-material-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
export default {
  name: 'CenterDashboard',
  data () {
    return {
      // searchTerm: '',
      newPostErrorMessage: null,
      selectedDestination: null,
      selectedDestinationFees: null,
      wilayas: [],
      communues: [],
      selectedWilaya1:{
        name:'Annaba-Annaba'
      },
      selectedWilaya2:null,
      selectedInfo: {
        selectedWilaya1:{

        },
        selectedWilaya2:{

        },
        vehicule:[0,0,0,0,0,0],
      },
      selectedWilaya: {
        name:null,
        communues: []
      },
      statusList: ['En attente', 'Accepté', 'Réfusé'],
      selectedCommunue: null,
      showPackageModal: false,
      showHisotryModal: false,
      isLoading: false,
      totalRows: 0,
      tableFilters: {
        approved: ''
      },
      outgoingTableFilters: {
        id: null,
        name: null,
        status: null,
        from_wilaya: null,
        date: null
      },
      history: {
        wilaya: null,
        communue: null,
        tracking_id: null,
        show: false,
        message: null,
        creation_date: {
          date: null,
          time: null
        }
      },
      packageDetail: {
        home_fees:0,
      },
      columns:[
        {
          label: 'Wilaya From',
          field: 'wilaya1',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Wilaya To',
          field: 'wilaya2',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: '400 KG',
          field: 'fees__0',
          width: '100px',
        },
        {
          label: '1.5 TN',
          field: 'fees__1',
          width: '100px',
        },
        {
          label: '2.5 TN',
          field: 'fees__2',
          width: '100px',
        },
        {
          label: '3.5 TN',
          field: 'fees__3',
          width: '100px',
        },
        {
          label: '10 TN',
          field: 'fees__4',
          width: '100px',
        },
        {
          label: '20 TN',
          field: 'fees__5',
          width: '100px',
        },
        
      ],
      newPost: {
        email:null,
        password:null,
        wilaya:null,
        communue:null,
        phone:null,
        address:null,
        name: null,
        home_fees: null,
        code: null
      },
      rows: [],
      outgoingRows: [],
      showLoginModal: false,
      showPostModal: false,
      showWilayaModal: false,
      preparedRow: null,
      preparedAction: null,
      feesExceptions: [],
      popoverShow : false,
    }
  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.getWilayas()
    this.loadPrices()
    // this.columns[4].filterOptions.filterDropdownItems = this.statusList
    this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.statusList);
  },
  methods: {
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.wilayas.push(tag)
      this.selectedDestination = tag
    },
    updateSelectedWilaya2() {
      this.selectedInfo.selectedWilaya2 = this.selectedWilaya2.name
    },
    updateSelectedWilaya1() {
      this.selectedInfo.selectedWilaya1 = this.selectedWilaya1.name
    },
    reloadItems() {
      this.isLoading = true
      this.loadPrices()      
    },
    onClose(){
      this.popoverShow = false
    },
    delete_post(){
      axios.post('delete-post',{auth_token: localStorage.getItem('auth_token'),
      code: this.packageDetail.code}).then(response =>{
        this.popoverShow = false
        this.showLoginModal = false
        this.loadPrices()
      })
    },
    getWilayas() {
      axios.post('get-wilayas').then(response => {
        if (response.data.status == 'success') {
          this.wilayas = response.data.data
          let wilayas = []
          this.wilayas.forEach(element => {
            wilayas.push(element.name)
          });
          // this.$set(this.columns[2].filterOptions, 'filterDropdownItems', wilayas);
          // this.columns[2].filterOptions.filterDropdownItems = wilayas
        }
      })
    },
    prepareAction(row,action) {
      this.preparedRow = row
      this.preparedAction = action
      this.showLoginModal = true
    },
    confirmAction() {
      if (this.preparedAction == 'accept') this.acceptProvider(this.preparedRow)
      else if (this.preparedAction == 'refuse') this.refuseProvider(this.preparedRow)
      this.showLoginModal = false
      this.loadPrices()
    },
    handleModalHide(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.showWilayaModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    hideModal(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.showWilayaModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    loadPrices() {
      axios.post('admin-get-btw', {
        auth_token: localStorage.getItem('auth_token'),
        btw_type: 'all',
      }).then(response => {
        if (response.data.status == 'success')
          this.rows = response.data.data
      })
    },
    rowClick(params) {
      // if (!this.showOrderWithDuplicates && params.event.target.localName !== "input" && params.event.target.localName !== "select" && params.event.target.localName !== "option" && params.event.target.className !== "vgt-checkbox-col" && params.event.target.className !== "dropdown-item" && params.event.target.type !== "button" && params.event.target.tagName !== "A") {
      this.packageDetail = params.row
      if (params.event.target.id == "status-btn") {
      }
      else if (params.event.target.id == "history-btn") {
        this.showHisotryModal = true
        this.history.tracking_id = params.row.tracking_id
      } else {
        this.showLoginModal = true
        this.feesExceptions = []
      }
    },
    changeStatus(row, tableType) {
      axios.post('center-change-status', {
        auth_token: localStorage.getItem('auth_token'),
        tracking_id: row.tracking_id,
        new_status: row.status
      }).then(response => {
        if (response.data.status == 'success') {
          if (tableType == 'incoming') this.loadIncomingItems()
          else if (tableType == 'outgoing') this.loadOutgoingItems()
        }
      })
    },
    onColumnFilter(params) {
      console.log(params)
      this.tableFilters = params.columnFilters
      if (params.columnFilters.approved_value == this.statusList[0]) this.tableFilters.approved = null
      else if (params.columnFilters.approved_value == this.statusList[1]) this.tableFilters.approved = true
      else if (params.columnFilters.approved_value == this.statusList[2]) this.tableFilters.approved = false
      else this.tableFilters.approved = ''
      this.loadPrices()
    },
    onSortChange(params) {
      let t = params[0].type
      let f = params[0].field
      this.rows.sort(function(a, b) {
        if (t == 'asc') {
          if ( a[f] < b[f] ){
            return -1;
          }
          if ( a[f] > b[f] ){
            return 1;
          }
        }
        else if (t == 'desc') {
          if ( a[f] < b[f] ){
            return 1;
          }
          if ( a[f] > b[f] ){
            return -1;
          }
        }
      });
    },
    onPageChange(params) {
      console.log(params)
    },
    onPerPageChange(params) {
      console.log(params)
    },
    // this.$set(this.columns[5].filterOptions, 'filterValue', '');
    refuseProvider(row) {
      axios.post('admin-refuse-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = false
        }
      })
    },
    acceptProvider(row) {
      axios.post('admin-accept-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = true
        }
      })
    },
    addFeesException() {
      this.feesExceptions.push({
        post_code: null,
        fees: null
      })
    },
    removeFeesExeception(index) {
      this.feesExceptions.splice(index,1)
    },
    changeFees() {
      axios.post('admin-change-post-fees', {
        auth_token: localStorage.getItem('auth_token'),
        code: this.packageDetail.code,
        home_fees: this.packageDetail.home_fees,
        fees_exceptions: this.feesExceptions,
        dlv_type: this.packageDetail.dlv_type,
      }).then(response => {
        if (response.data.status == 'success') {
          this.showLoginModal = false
          this.loadPrices()
        }
      })
    },
    addNewWilaya() {
      axios.post('admin-add-wilaya', {
        auth_token: localStorage.getItem('auth_token'),
        selectedDestination : this.selectedDestination.name,
        selectedDestinationFees:this.selectedDestinationFees
      }).then(response => {
        this.showWilayaModal = false
      })
    },
    addNewPrice() {
      axios.post('admin-create-btw', {
        auth_token: localStorage.getItem('auth_token'),
        w1: this.selectedInfo.selectedWilaya1.name,
        w2: this.selectedInfo.selectedWilaya2.name,
        c1: this.selectedInfo.selectedCommunue1,
        c2: this.selectedInfo.selectedCommunue2,
        fees: this.selectedInfo.vehicule
      }).then(response => {
        if (response.data.status == 'success') {
          this.loadPrices()
          this.showPostModal = false
          this.newPost= {
            email:null,
            password:null,
            wilaya:null,
            communue:null,
            phone:null,
            address:null,
            name: null,
            home_fees: null,
            code: null
          }
          this.feesExceptions = []
          this.newPostErrorMessage = null
        } else {
          this.newPostErrorMessage = response.data.error_message
        }
      })
    },
  },
}
</script>
<style>
.vgt-responsive {
  min-height: 450px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>